<template>
  <!-- Topbar Start -->
  <NavItem />
  <!-- Topbar End -->

  <!-- Navbar Start -->
  <div class="container-fluid mb-5">
    <div class="row border-top px-xl-5">
      <div class="col-lg-3 d-none d-lg-block">
        <a
          class="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100"
          data-toggle="collapse"
          href="#navbar-vertical"
          style="height: 65px; margin-top: -1px; padding: 0 30px"
        >
          <h6 class="m-0">Categories</h6>
          <i class="fa fa-angle-down text-dark"></i>
        </a>
        <nav
          class="collapse show navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0"
          id="navbar-vertical"
        >
          <div class="navbar-nav w-100 overflow-hidden" style="height: 410px">
            <div class="nav-item dropdown">
              <a href="#" class="nav-link" data-toggle="dropdown"
                >Dresses <i class="fa fa-angle-down float-right mt-1"></i
              ></a>
              <div
                class="dropdown-menu position-absolute bg-secondary border-0 rounded-0 w-100 m-0"
              >
                <a href="" class="dropdown-item">Men's Dresses</a>
                <a href="" class="dropdown-item">Women's Dresses</a>
                <a href="" class="dropdown-item">Baby's Dresses</a>
              </div>
            </div>
            <a href="/cars" class="nav-item nav-link">Car Rent</a>
            <a href="/houses" class="nav-item nav-link">Houses</a>
          </div>
        </nav>
      </div>
      <div class="col-lg-9">
        <nav
          class="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0"
        >
          <a href="" class="text-decoration-none d-block d-lg-none">
            <h1 class="m-0 display-5 font-weight-semi-bold">
              <span class="text-primary font-weight-bold border px-3 mr-1"
                >SIMAZA</span
              >Group
            </h1>
          </a>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          >
            <div class="navbar-nav mr-auto py-0">
              <a href="/" class="nav-item nav-link active">Home</a>
              <a href="/shop" class="nav-item nav-link">Fashions</a>
              <a href="/cars" class="nav-item nav-link">Car Rent</a>
              <a href="/houses" class="nav-item nav-link">Houses</a>
              <a href="/teams" class="nav-item nav-link">Teams</a>
              <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >Pages</a
                >
                <div class="dropdown-menu rounded-0 m-0">
                  <a href="/cars" class="dropdown-item">Car Rent</a>
                  <a href="/houses" class="dropdown-item">Houses</a>
                </div>
              </div>
              <a href="/contact" class="nav-item nav-link">Contact</a>
            </div>
            <div class="navbar-nav ml-auto py-0">
              <a href="http://localhost:8085/login" class="nav-item nav-link"
                >Login</a
              >
              <a href="" class="nav-item nav-link">Register</a>
            </div>
          </div>
        </nav>
        <div id="header-carousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" style="height: 410px">
              <img class="img-fluid" src="img/hero-img/_JBP6807.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4">
                    Fashionable Dress
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3">Shop Now</a>
                </div>
              </div>
            </div>
            <div class="carousel-item" style="height: 410px">
              <img class="img-fluid" src="img/hero-img/_JBP6812.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4">
                    Reasonable Price
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3">Shop Now</a>
                </div>
              </div>
            </div>
            <div class="carousel-item" style="height: 410px">
              <img class="img-fluid" src="img/hero-img/_JBP6820.jpg" alt="Image" />
              <div
                class="carousel-caption d-flex flex-column align-items-center justify-content-center"
              >
                <div class="p-3" style="max-width: 700px">
                  <h4 class="text-light text-uppercase font-weight-medium mb-3">
                    10% Off Your First Order
                  </h4>
                  <h3 class="display-4 text-white font-weight-semi-bold mb-4">
                    Reasonable Price
                  </h3>
                  <a href="" class="btn btn-light py-2 px-3">Shop Now</a>
                </div>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#header-carousel"
            data-slide="prev"
          >
            <div class="btn btn-dark" style="width: 45px; height: 45px">
              <span class="carousel-control-prev-icon mb-n2"></span>
            </div>
          </a>
          <a
            class="carousel-control-next"
            href="#header-carousel"
            data-slide="next"
          >
            <div class="btn btn-dark" style="width: 45px; height: 45px">
              <span class="carousel-control-next-icon mb-n2"></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Featured Start -->
  <div class="container-fluid pt-5">
    <div class="row px-xl-5 pb-3">
      <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
        <div
          class="d-flex align-items-center border mb-4"
          style="padding: 30px"
        >
          <h1 class="fa fa-check text-primary m-0 mr-3"></h1>
          <h5 class="font-weight-semi-bold m-0">Quality Product</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
        <div
          class="d-flex align-items-center border mb-4"
          style="padding: 30px"
        >
          <h1 class="fa fa-shipping-fast text-primary m-0 mr-2"></h1>
          <h5 class="font-weight-semi-bold m-0">Free Shipping</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
        <div
          class="d-flex align-items-center border mb-4"
          style="padding: 30px"
        >
          <h1 class="fas fa-exchange-alt text-primary m-0 mr-3"></h1>
          <h5 class="font-weight-semi-bold m-0">14-Day Return</h5>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 pb-1">
        <div
          class="d-flex align-items-center border mb-4"
          style="padding: 30px"
        >
          <h1 class="fa fa-phone-volume text-primary m-0 mr-3"></h1>
          <h5 class="font-weight-semi-bold m-0">24/7 Support</h5>
        </div>
      </div>
    </div>
  </div>
  <!-- Featured End -->

  <!-- Categories Start -->
  <div class="container-fluid pt-5">
    <div class="row px-xl-5 pb-3">
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6701.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Men's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6711.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Women's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6737.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6734.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
       <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6793.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6784.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6732.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a href="" class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6743.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Categories End -->

  <!-- Offer Start -->
  <div class="container-fluid offer pt-5">
    <div class="row px-xl-5">
      <div class="col-md-6 pb-4">
        <div
          class="position-relative bg-secondary text-center text-md-right text-white mb-2 py-5 px-5"
        >
          <img src="img/JPEG/_JBP6763.jpg" alt="" />
          <div class="position-relative" style="z-index: 1">
            <h5 class="text-uppercase text-primary mb-3">
              20% off the all order
            </h5>
            <h1 class="mb-4 font-weight-semi-bold">Bags Collection</h1>
            <a href="" class="btn btn-outline-primary py-md-2 px-md-3"
              >Shop Now</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-6 pb-4">
        <div
          class="position-relative bg-secondary text-center text-md-left text-white mb-2 py-5 px-5"
        >
          <img src="img/JPEG/_JBP6778.jpg" alt="" />
          <div class="position-relative" style="z-index: 1">
            <h5 class="text-uppercase text-primary mb-3">
              20% off the all order
            </h5>
            <h1 class="mb-4 font-weight-semi-bold">Bags Collection</h1>
            <a href="" class="btn btn-outline-primary py-md-2 px-md-3"
              >Shop Now</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Offer End -->

  <!-- cars Start -->
  <div class="container-fluid pt-5">
    <div class="text-center mb-4">
      <h2 class="section-title px-5">
        <span class="px-2">Car Rent We Have in Rwanda</span>
      </h2>
    </div>
    <div class="row px-xl-5 pb-3">
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Land Cruiser</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6493.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Benz</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6539.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Benz</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6497.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Land Cruiser</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6493.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6507.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6515.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6522.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6524.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
    </div>
  </div>
  <!-- cars End -->

   <!-- houses Start -->
  <div class="container-fluid pt-5">
    <div class="text-center mb-4">
      <h2 class="section-title px-5">
        <span class="px-2">House for Rent We Have in Rwanda</span>
      </h2>
    </div>
    <div class="row px-xl-5 pb-3">
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Land Cruiser</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6578.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Benz</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6576.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Benz</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6585.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Land Cruiser</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6603.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6607.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6614.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6647.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6671.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6649.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 pb-1">
        <div
          class="cat-item d-flex flex-column border mb-4"
          style="padding: 30px"
        >
          <p class="text-right">Product Name</p>
          <a class="cat-img position-relative overflow-hidden mb-3">
            <img class="img-fluid" src="img/JPEG/_JBP6678.jpg" alt="" />
          </a>
          <!-- <h5 class="font-weight-semi-bold m-0">Baby's dresses</h5> -->
        </div>
      </div>
    </div>
  </div>
  <!-- houses End -->

  <!-- Subscribe Start -->
  <div class="container-fluid bg-secondary my-5">
    <div class="row justify-content-md-center py-5 px-xl-5">
      <div class="col-md-6 col-12 py-5">
        <div class="text-center mb-2 pb-2">
          <h2 class="section-title px-5 mb-3">
            <span class="bg-secondary px-2">Stay Updated</span>
          </h2>
          <p>
            Amet lorem at rebum amet dolores. Elitr lorem dolor sed amet diam
            labore at justo ipsum eirmod duo labore labore.
          </p>
        </div>
        <form action="">
          <div class="input-group">
            <input
              type="text"
              class="form-control border-white p-4"
              placeholder="Email Goes Here"
            />
            <div class="input-group-append">
              <button class="btn btn-primary px-4">Subscribe</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Subscribe End -->

  <!-- Footer Start -->
   <div class="container-fluid bg-secondary text-dark mt-5 pt-5">
    <div class="row px-xl-5 pt-5">
      <div class="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
        <a href="" class="text-decoration-none">
          <h1 class="mb-4 display-5 font-weight-semi-bold">
            <img src="img/JPEG/Simaza Logo-01png.png" alt="Image"  width="100" style="margin-left: 50px;" />
          </h1>
        </a>
        <p>
          In Simaza Group, We are intended  to be the global services and products provider with a high efficiency, durable with  high-quality to our consumers , in surplus to get all market opportunities served.
        </p>
        <p class="mb-2">
          <i class="fa fa-map-marker-alt text-primary mr-3"></i>Kicukiro Dstr,Nyanza Village Kk379St,no 7
        </p>
        <p class="mb-2">
          <i class="fa fa-envelope text-primary mr-3"></i>simazagroup@1gmail.com
        </p>
        <p class="mb-0">
          <i class="fa fa-phone-alt text-primary mr-3"></i>+250788253109
        </p>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="row">
          <div class="col-md-4 mb-5">
            <h5 class="font-weight-bold text-dark mb-4">Quick Links</h5>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-dark mb-2" href="/"
                ><i class="fa fa-angle-right mr-2"></i>Home</a
              >
              <a class="text-dark mb-2" href="/shop"
                ><i class="fa fa-angle-right mr-2"></i>Our Shop</a
              >
              <a class="text-dark mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i>Shop Detail</a
              >
              <a class="text-dark mb-2" href="/cart"
                ><i class="fa fa-angle-right mr-2"></i>Shopping Cart</a
              >
              <a class="text-dark mb-2" href="/checkout"
                ><i class="fa fa-angle-right mr-2"></i>Checkout</a
              >
              <a class="text-dark" href="/contact"
                ><i class="fa fa-angle-right mr-2"></i>Contact Us</a
              >
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="font-weight-bold text-dark mb-4">Quick Links</h5>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-dark mb-2" href="/"
                ><i class="fa fa-angle-right mr-2"></i>Home</a
              >
              <a class="text-dark mb-2" href="/shop"
                ><i class="fa fa-angle-right mr-2"></i>Our Shop</a
              >
              <a class="text-dark mb-2" href="detail.html"
                ><i class="fa fa-angle-right mr-2"></i>Shop Detail</a
              >
              <a class="text-dark mb-2" href="cart.html"
                ><i class="fa fa-angle-right mr-2"></i>Shopping Cart</a
              >
              <a class="text-dark mb-2" href="/checkout"
                ><i class="fa fa-angle-right mr-2"></i>Checkout</a
              >
              <a class="text-dark" href="/contact"
                ><i class="fa fa-angle-right mr-2"></i>Contact Us</a
              >
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="font-weight-bold text-dark mb-4">Newsletter</h5>
            <form action="">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control border-0 py-4"
                  placeholder="Your Name"
                  required="required"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control border-0 py-4"
                  placeholder="Your Email"
                  required="required"
                />
              </div>
              <div>
                <button
                  class="btn btn-primary btn-block border-0 py-3"
                  type="submit"
                >
                  Subscribe Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row border-top border-light mx-xl-5 py-4">
      <div class="col-md-6 px-xl-0">
        <p class="mb-md-0 text-center text-md-left text-dark">
          &copy;
          <a class="text-dark font-weight-semi-bold" href="#">SIMAZA Group</a
          >. All Rights Reserved.
        </p>
      </div>
      <div class="col-md-6 px-xl-0 text-center text-md-right">
        <img class="img-fluid" src="img/payments.png" alt="" />
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
  <a href="#" class="btn btn-primary back-to-top"
    ><i class="fa fa-angle-double-up"></i
  ></a>
</template>
<script>
// import ShopItem from '../components/ShopItem.vue'
import NavItem from '../components/NavItem.vue'
import axios from 'axios'
import { errorHandlingMixins } from '../mixins/errorHandlingMixins.js'
export default {
  mixins: [errorHandlingMixins],
  components: {
    // ShopItem,
    NavItem
  },
  data () {
    return {
      shops: []
    }
  },
  methods: {
    async listProducts () {
      try {
        const response = await axios.get(
          '/api/list-product/'
        )
        this.shops = response.data
      } catch (error) {
        this.displayUnauthorized(error)
      }
    }
  },
  mounted () {
    this.listProducts()
  }
}
</script>
