export const errorHandlingMixins = {

  methods: {
    displayUnauthorized (error) {
      if (error.response === undefined) {
        alert('something went wrong please contact support administrator')
        return
      }

      if (error.response.data.errors) {
        alert(error.response.data.errors[0])
        return
      }
      if (error.response.data.message) {
        alert(error.response.data.message)
      }
    }
  }
}
